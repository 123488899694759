#layout {
  #header {
    display: flex;
    height: 5vh;
    background-color: #17baa5;
    align-items: center;
    .logo {
      color: black;
      margin-left: 10px;
      font-size: larger;
      font-weight: bolder;
      font-family: 'Century Gothic', CenturyGothic, Geneva, AppleGothic, sans-serif;
      white-space: nowrap;
    }
    .triggerBackupWrapper {
      width: 100%;
      display: flex;
      justify-content: right;
      margin-right: 5px;
    }
  }
  width: 100vw;
  height: 95vh;
}
