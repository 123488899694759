@import '../../variables.scss';
.list {
  width: 60%;
  margin: auto;
  height: 95vh;
  overflow-y: scroll;
  .item {
    padding: 10px;
    border-radius: 5px;
    background-color: $secondary-color;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
